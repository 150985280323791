.Fit-out-process {
  width: 100vw;
  height: 85vh;
  min-height: 70vh;
}
.Fit-out-process-heading {
  width: 100%;
  height: 100%;
  display: flex;
  /* justify-content: center; */
  padding-left: 12%;
  align-items: center;
  position: relative;
  isolation: isolate;
}
.Fit-out-process-heading-animation {
  width: 300px;
  height: 50px;
  margin-bottom: 150px;
}
.FitHeaderBg {
  position: absolute;
  width: 100%;
  height: 80%;
  top: 0;
  bottom: 0;
  object-fit: cover;
  z-index: -1;
  background-position: center;
  background-size: cover;
  animation: fitImgAnimation 5s ease-in-out infinite alternate-reverse;
}
@keyframes fitImgAnimation {
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}
.Fit-out-process-heading::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 200%;
  background-color: black;
  top: -100%;
  left: 0;
  z-index: -1;
  opacity: 0.5;
}
.Fit-out-process-heading h1 {
  color: #fff;
  font-size: 5rem;
  text-align: center;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  text-shadow: 5px 5px 2px black;
  transform-origin: left;
  cursor: pointer;
}

.Fit-Form-Section {
  width: 100vw;
  height: auto;
  background-image: var(--Fitform-background);
  transition: all 0.3s ease-in-out;
  background-size: cover;
  background-position: center;
  padding: 100px 0;
  position: relative;
  overflow: hidden;
}
.form-ball1,
.form-ball2,
.form-ball3,
.form-ball4 {
  position: absolute;
  filter: blur(4px);
  background-image: linear-gradient(45deg, #000000, #000000, #8c8c8c, #8c8c8c);
  border-radius: 50%;
  box-shadow: #000000 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px,
    #000000 0px 4px 6px, #8c8c8c8c 0px 12px 13px, #8c8c8c 0px -3px 5px;
  animation: FitBallAni 2s ease-in-out infinite alternate-reverse;
}
@keyframes FitBallAni {
  from {
    transform: scale(0.9);
    transform: translateY(20px);
  }
  to {
    transform: scale(1);
    transform: translateY(0);
  }
}
.form-ball1 {
  top: 10px;
  left: 10px;
  width: 200px;
  height: 200px;
}
.form-ball2 {
  bottom: 50px;
  right: 50px;
  width: 100px;
  height: 100px;
}
.form-ball3 {
  bottom: -60px;
  left: 150px;
  width: 150px;
  height: 150px;
}
.form-ball4 {
  top: -10px;
  right: 500px;
  width: 80px;
  height: 80px;
}
.Fit-Form-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}
/* .form-rotate-spinner {
  position: absolute;
  background: linear-gradient(135deg, red, #01ff44);
  width: 150%;
  height: 100%;
  top: 0px;
  left: 0;
  animation: formSpinnerAni 4s ease-in-out infinite;
  animation-play-state: paused;
}
.Fit-Form-Container:hover .form-rotate-spinner {
  animation-play-state: running;
}
@keyframes formSpinnerAni {
  from {
    filter: hue-rotate(0deg);
    transform: rotateZ(0deg);
  }
  to {
    filter: hue-rotate(360deg);
    transfrm: rotateZ(360deg);
  }
} */
.Fit-Form-Container form {
  width: 100%;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 10px;
  border-radius: 10px;
  display: flex;
  gap: 30px 100px;
  flex-wrap: wrap;
  box-shadow: #a020f0 0px 1px 20px;
  position: relative;
  isolation: isolate;
  /* overflow: hidden; */
  transform-origin: top;
}
.Fit-Form-Container form::before {
  content: "";
  width: 100%;
  height: 0;
  background-color: #a020f0;
  position: absolute;
  opacity: 0.06;
  z-index: -1;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  /* border-radius: 50%; */
  transition: all 0.4s ease-in-out;
}
.Fit-Form-Container form:hover::before {
  height: 100%;
}
.Fit-Name input::placeholder {
  color: orangered;
}
.input-groups {
  width: 40%;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}
.Fit-Notes {
  width: 90%;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
}
.input-groups select optgroup {
  background-color: #000000;
}
.Fit-Notes select,
.Fit-Notes input {
  height: 60px !important;
}
.input-groups select,
.input-groups input {
  width: 100%;
  height: 40px;
  border-radius: 100px;
  border: 1px solid var(--color2);
  background-color: transparent;
  color: #fff;
  outline: none;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  padding: 0 20px;
}
.input-groups label {
  position: absolute;
  color: var(--color2);
  font-size: 1.2em;
  font-weight: 500;
  top: 50%;
  transform: translateY(-50%);
  left: 25px;
  transition: all 0.2s ease-in-out;
  pointer-events: none;
}
.input-groups input:focus ~ label,
.input-groups input:valid ~ label,
.input-groups select:focus ~ label,
.input-groups select:valid ~ label {
  font-size: 0.9em;
  top: -1.3px;
  transition: all 0.2s ease-in-out;
  background-color: var(--Fitform-label-bg);
  padding: 0 5px;
  border-radius: 100px;
  color: var(--color2);
}
.input-groups select:focus,
.input-groups input:focus {
  border: 2px solid #a020f0;
}
.formFit-button-group {
  display: flex;
  justify-content: space-between;
  width: 65%;
  margin: 20px 10px 0 10px;
}
.Fit-Request-Type,
.Fit-Priority,
.Fit-Uit-Id,
.Fit-Case-Category,
.Fit-Parent-Case,
.Fit-Request-Document-Type {
  position: relative;
}
.Fit-Request-Document-Type .drop-down {
  color: #fff;
}
.Fit-Request-Document-Type .drop-down .drop-down-child {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 0 10px;
}
.Fit-Request-Document-Type .drop-down .drop-down-child:hover {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.1)
  );
}

/* .read-only {
  pointer-events: none;
  opacity: 0.6;
}

.drop-down-child.selected p {
  color: gray;
  opacity: 0.6;
} */
.drop-down-child.selected.drop-down-child:hover {
  background-color: transparent;
}
.drop-down-child-tickMark {
  display: inline-block;
  margin-left: 10px;
  margin-top: 7px;
  color: green;
  font-size: 10px;
}
.Fit-Uit-Id .drop-down,
.Fit-Request-Type .drop-down,
.Fit-Case-Category .drop-down {
  position: absolute;
  top: 40px;
  left: 0px;
  width: 100%;
  z-index: 999;
  height: auto;
  max-height: 280px;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  background-color: var(--background-color);
}
.Fit-Request-Document-Type .drop-down {
  position: absolute;
  top: 40px;
  left: 0px;
  width: 100%;
  z-index: 999;
  height: auto;
  max-height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  background-color: var(--background-color);
}
.Fit-Parent-Case .drop-down {
  position: absolute;
  top: 40px;
  left: -10%;
  width: 120%;
  z-index: 999;
  height: auto;
  max-height: 280px;
  overflow-y: scroll;
  overflow-x: scroll;
  scroll-snap-type: mandatory;
  background-color: var(--background-color);
}
.Fit-Case-Category .drop-down {
  color: #fff;
  padding: 10px 20px 0 20px;
  margin: 0;
}
.drop-down-parent-h5-triIcon {
  margin-right: 10px;
  transition: transform 0.3s ease;
  margin-bottom: 3px;
}

.drop-down-parent h5.rotated .drop-down-parent-h5-triIcon {
  transform: rotate(90deg);
}
.Fit-Priority .drop-down {
  position: absolute;
  top: 40px;
  left: 0px;
  width: 100%;
  z-index: 999;
  height: auto;
  max-height: 130px;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  background-color: var(--background-color);
}
.Fit-Parent-Case .drop-down::-webkit-scrollbar-corner {
  background: #000000;
}

.Fit-Request-Type .drop-down::-webkit-scrollbar-track,
.Fit-Priority .drop-down::-webkit-scrollbar-track,
.Fit-Uit-Id .drop-down::-webkit-scrollbar-track,
.Fit-Parent-Case .drop-down::-webkit-scrollbar-track,
.Fit-Request-Document-Type .drop-down::-webkit-scrollbar-track {
  background-color: var(--background-color);
}
.Fit-Request-Type .drop-down table,
.Fit-Priority .drop-down table,
.Fit-Uit-Id .drop-down table,
.Fit-Parent-Case .drop-down table,
.Fit-Request-Document-Type .drop-down table {
  border-collapse: collapse;
  width: 100%;
}
.Fit-Request-Type .drop-down table th,
.Fit-Priority .drop-down table th,
.Fit-Uit-Id .drop-down table th,
.Fit-Parent-Case .drop-down table th,
.Fit-Request-Document-Type .drop-down table th {
  position: sticky;
  top: -5px;
}
.Fit-Request-Type .drop-down table tr th,
.Fit-Request-Type .drop-down table tr td,
.Fit-Priority .drop-down table tr th,
.Fit-Priority .drop-down table tr td,
.Fit-Uit-Id .drop-down table tr th,
.Fit-Uit-Id .drop-down table tr td,
.Fit-Parent-Case .drop-down table tr th,
.Fit-Parent-Case .drop-down table tr td,
.Fit-Request-Document-Type .drop-down table tr th,
.Fit-Request-Document-Type .drop-down table tr td {
  outline: none !important;
  border: none !important;
  background-color: var(--background-color);
  color: var(--color2);
  padding-left: 20px;
}
.Fit-Name {
  position: relative;
}
.alert-Fit-Name {
  position: absolute;
  width: 45%;
  height: 50%;
  background-color: #8c8c8c;
  color: orangered;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  top: 50px;
  left: 20%;
  isolation: isolate;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 20px;
  animation: fitNameErrorAni 0.5s ease-in-out;
  transform-origin: top;
  opacity: 0.8;
}
@keyframes fitNameErrorAni {
  from {
    transform: scaleY(0);
  }
  to {
    transform: scaleY(1);
  }
}
.alert-Fit-Name::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #8c8c8c;
  transform: rotateZ(45deg);
  top: -5px;
  z-index: -1;
}
.alert-Fit-Name p {
  margin: 10px 0;
  letter-spacing: 1px;
}
.upload-files-arrow {
  color: rgb(20, 237, 20);
  font-size: 15px;
  margin-bottom: 2px;
  margin-right: 10px;
}
.upload-files-show-star-h6 {
  color: #a020f0;
  font-size: 10px;
  margin-right: 8px;
  margin-bottom: 3.7px;
}
.upload-files-show-star {
  color: #ffffff;
  font-size: 8px;
  margin-right: 8px;
  margin-bottom: 2px;
}
.upload-files-cancel {
  color: orangered;
  margin-left: 5px;
  margin-bottom: 1.5px;
}
.upload-files-show-name {
  padding-left: 10px;
  font-weight: 900;
  margin-top: 10px;
  cursor: pointer;
}
.selectedRequestDocIds-h6 {
  color: #000000;
  margin-top: 0px;
  display: flex;
  cursor: pointer;
}
.selectedRequestDocIds-h6-h6 {
  margin-top: 12px;
  color: #000000;
  font-weight: 900;
}
.show-file-name {
  width: 100%;
  display: grid;
  justify-content: center;
}
@media screen and (max-width: 1200px) {
  .Fit-Notes {
    width: 94%;
  }
  .Fit-out-process-heading {
    padding-left: 0%;
  }
  .alert-Fit-Name p {
    font-size: 12px;
  }
}
@media screen and (max-width: 900px) {
  .input-groups {
    margin: 0px;
  }
  .form-ball4 {
    right: 200px;
  }
  .Fit-out-process-heading {
    padding-left: 5%;
  }
  .Fit-out-process-heading-animation {
    width: 200px;
    margin-bottom: 100px;
  }
  .Fit-out-process-heading h1 {
    font-size: 3.5rem;
  }
  .alert-Fit-Name p {
    font-size: 10px;
  }
  .alert-Fit-Name {
    width: 60%;
    height: 40%;
  }
}
@media screen and (max-width: 800px) {
  .drop-down-parent h5 {
    font-size: 18px;
  }
  .drop-down-child p {
    font-size: 12px;
    letter-spacing: 1.5px;
  }
  .input-groups label {
    font-size: 1em;
  }
}
@media screen and (max-width: 770px) {
  .Fit-Notes {
    width: 88% !important;
  }
  .input-groups {
    width: 40%;
  }
  .Fit-Form-Container form {
    padding: 50px 0px;
    gap: 30px 45px;
  }
  .Fit-out-process-heading {
    padding-left: 0%;
  }
}
@media screen and (max-width: 576px) {
  .Fit-out-process-heading {
    padding-left: 0%;
    justify-content: center;
  }
  .Fit-out-process-heading-animation {
    width: 300px;
    margin-bottom: 380px;
    margin-left: -50px;
    position: absolute;
  }
  .Fit-out-process-heading h1 {
    font-size: 2.5em;
    margin-top: 100px;
  }
  .Fit-Notes {
    width: 100% !important;
  }
  .Fit-Notes select,
  .Fit-Notes input {
    height: 40px !important;
  }
  .input-groups {
    width: 100%;
  }
  .Fit-Form-Container form {
    padding: 50px 10px;
    gap: 30px 30px;
  }
  .form-ball1 {
    top: 10px;
    left: 10px;
    width: 150px;
    height: 150px;
  }
  .form-ball2 {
    bottom: 50px;
    right: 50px;
    width: 80px;
    height: 80px;
  }
  .form-ball3 {
    bottom: -30px;
    left: 50px;
    width: 100px;
    height: 100px;
  }
  .form-ball4 {
    top: -10px;
    right: 50px;
    width: 60px;
    height: 60px;
  }
  .formFit-button-group {
    width: 100%;
  }
  .input-groups label {
    font-size: 1.2em;
  }
  .upload-files-arrow {
    font-size: 10px;
    margin-bottom: 2px;
  }
  .upload-files-show-star-h6 {
    font-size: 10px;
    margin-bottom: 3.7px;
  }
  .upload-files-show-star {
    font-size: 8px;
    margin-bottom: 2px;
  }

  .upload-files-show-name {
    font-weight: 900;
    font-size: 10px;
  }

  .selectedRequestDocIds-h6 {
    color: #000000;
    display: flex;
    cursor: pointer;
  }
  .selectedRequestDocIds-h6-h6 {
    margin-top: 9px;
    font-size: 10px;
    color: #000000;
    font-weight: 900;
  }
}
