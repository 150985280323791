@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
nav {
  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
}

.Maintenance-nav-link {
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
  border: 0.5px solid transparent;
  padding: 5px 15px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  transition: all 0.3s ease-in-out;
  color: #fff;
  cursor: pointer;
}
.Maintenance-nav-link-scroll {
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
  border: 0.5px solid transparent;
  padding: 5px 15px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  transition: all 0.3s ease-in-out;
  color: var(--color);
  cursor: pointer;
}
.Maintenance-nav-scroll {
  color: var(--color2);
}
.Maintenance-nav-link:hover {
  border: 0.5px solid #a020f0;
  color: #a020f0;
  transition: all 0.3s ease-in-out;
}
.Maintenance-nav-link-scroll:hover {
  border: 0.5px solid #a020f0;
  color: #a020f0;
  transition: all 0.3s ease-in-out;
}
.Maintenance-link-arrow {
  font-size: 25px;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 576px) {
  nav {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100vw;
    height: auto;
    padding: 20px 0 20px 10px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    gap: 10px;
    left: -0.3px;
    top: 95px;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    );
    justify-content: flex-start;
    align-items: flex-start;
    animation: navAnuimation 0.5s ease-in-out;
    transform-origin: top;
    box-shadow: var(--bottom-shadow);
  }
  .Maintenance-nav-scroll {
    background: var(--Fitnav-bg);
  }
  @keyframes navAnuimation {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }
  .Maintenance-nav-link {
    letter-spacing: 1.8px;
    font-size: 16px;
  }
  .Maintenance-nav-link:hover {
    border: 0.5px solid #fff;
    color: #fff;
    transition: all 0.3s ease-in-out;
  }
}
