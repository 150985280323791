header {
  height: 100px;
  color: #fff;
  display: grid;
  align-content: center;
  z-index: 999;
}
.Fit-header-unscroll {
  color: #fff;
  position: relative;
}
.Fit-header-scroll {
  color: var(--color2);
  background-color: #d9afd9;
  background-image: var(--Fitnav-background);
  box-shadow: var(--bottom-shadow);
  animation: header-scroll 0.4s ease-in-out;
  transform-origin: top;
}
@keyframes header-scroll {
  from {
    transform: translateY(-100px);
    transform: scaleY(0);
    opacity: 0.5;
  }
  to {
    transform: translateY(0);
    transform: scaleY(1);
    opacity: 1;
  }
}
.Fit-header-col1 {
  display: flex;
  justify-content: start;
  align-items: start;
  padding-left: 10px;
  margin-bottom: 40px;
  pointer-events: none;
}
.Fit-header-col2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.Fit-header-col3 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Fit-header-col1-subdiv img {
  width: 400px;
  height: 150px;
  filter: drop-shadow(1px 0px 10px black);
}
.Fit-hamburger {
  display: none;
}
.Fit-mode-class {
  display: flex;
  justify-content: center;
}
.Fit-mode-label {
  width: 60px;
  height: 30px;
  background-color: #c1c1c1;
  position: relative;
  box-shadow: #a020f0 0px 1px 15px;
}
.Fit-mode-label::before {
  content: "";
  width: 27px;
  height: 27px;
  background-color: rgb(31, 29, 29);
  border-radius: 50%;
  position: absolute;
  margin: 1.5px;
  transition: all 0.3s ease-in-out;
  box-shadow: #a020f0 0px 0px 6px 2px inset;
}
input[type="checkbox"] {
  display: none;
}
input[type="checkbox"]:checked + .mode-label {
}
input[type="checkbox"]:checked + .mode-label::before {
  transform: translateX(30px);
  transition: all 0.3s ease-in-out;
}
@media screen and (max-width: 1200px) {
  .Fit-header-col1-subdiv img {
    margin-left: -30px;
  }
}
@media screen and (max-width: 900px) {
  .Fit-header-col1-subdiv img {
    margin-left: -40px;
  }
}
@media screen and (max-width: 760px) {
  .Fit-header-col2 {
    padding-left: 50px;
  }
}
@media screen and (max-width: 576px) {
  .Fit-header-unscroll {
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    );
  }
  .Fit-header-col1-subdiv img {
    width: 300px;
    height: 120px;
  }
  .Fit-header-col1 {
    display: flex;
    justify-content: start;
    align-items: start;
    margin-bottom: 20px;
  }
  .Fit-header-col2 {
    display: flex;
    justify-content: center;
    padding-left: 0px;
    /* align-items: center; */
    /* position: relative; */
  }
  .Fit-header-col3 {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
  .Fit-hamburger {
    display: block;
    color: var(--color);
  }
  .Fit-nav-hide {
    display: none;
  }
  .nav-show {
    display: block;
  }
}
