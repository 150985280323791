@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&family=Rubik+Bubbles&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Pacifico&family=Rubik+Bubbles&display=swap");
/*------------------------ HOME-MAIN ----------------*/
.home {
  display: grid;
  place-content: center;
  height: 85vh;
  box-shadow: var(--bottom-shadow);
  transition: all 0.3s ease-in-out;
  position: relative;
}
.home::after {
  content: "";
  background-color: black;
  width: 100%;
  height: 120%;
  position: absolute;
  top: -20%;
  left: 0;
  z-index: -1;
  opacity: 0.6;
}
.home::before {
  content: "";
  width: 100%;
  height: 3px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  opacity: 0;
  background-color: #a020f0;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 1s ease-in-out;
}
.home-bg {
  position: absolute;
  width: 100%;
  height: 120%;
  top: -20%;
  bottom: 0;
  object-fit: cover;
  z-index: -1;
  background-position: center;
  background-size: cover;
  animation: imgAnimation 5s ease-in-out infinite alternate-reverse;
}
@keyframes imgAnimation {
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}
.home-rocket {
  width: 100px;
  animation: rocketAni 1s linear infinite alternate-reverse;
  filter: drop-shadow(8px 5px 10px black);
}
@keyframes rocketAni {
  from {
    transform: translateY(10px);
  }
  to {
    transform: translateY(-10px);
  }
}
.home .home-body {
  background-color: transparent;
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.home-card-body-h1 {
  font-size: 5rem;
  text-align: center;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  text-shadow: 5px 5px 2px black;
  cursor: pointer;
}
/*------------------------ HOME-MAIN ----------------*/

/*------------------------ HOME-FORM ----------------*/
.home-form {
  width: 100vw;
  background-image: var(--form-background);
  transition: all 0.3s ease-in-out;
  background-size: cover;
  background-position: center;
  padding: 100px 0;
}
.home-form .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 100px;
}
.form-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 100px;
}
.home-form .form-heading h1 {
  color: var(--color);
  transition: all 0.3s ease-in-out;
  text-align: center;
  font-size: 3rem;
  cursor: pointer;
}
.form-vector {
  width: 100px;
  animation: formVectorAnimation 1s linear infinite alternate-reverse;
}
@keyframes formVectorAnimation {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}
.home-form .container form {
  width: 100%;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 10px;
  border-radius: 10px;
  display: flex;
  gap: 10px 100px;
  flex-wrap: wrap;
  box-shadow: #a020f0 0px 1px 15px;
  position: relative;
  isolation: isolate;
  overflow: hidden;
  transform-origin: top;
}
.home-form .container form::before {
  content: "";
  width: 0;
  height: 0;
  background-color: #a020f0;
  position: absolute;
  opacity: 0.1;
  z-index: -1;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transition: all 0.5s ease-in-out;
}
.home-form .container form:hover::before {
  width: 150%;
  height: 150%;
}
.form-name,
.form-line-of-Business,
.form-gmail,
.form-selected,
.form-prperty-type,
.form-Unit,
.form-required-area {
  width: 40%;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.form-phone-number {
  width: 40%;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 10px;
}
.form-brand {
  width: 90%;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.form-notes {
  width: 90%;
  margin: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.form-input-phone-div {
  width: 100%;
}
.form-input-phone {
  width: 100%;
  outline: none !important;
}

.PhoneInputInput {
  background-color: var(--background-color) !important;
  color: var(--color2);
  height: 40px;
  border-radius: 15px;
  border: none;
  border: 1px solid #a020f0;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.PhoneInputInput:focus {
  outline: none;
  background-color: var(--background-color);
  color: var(--color2);
}
.PhoneInputCountryIcon {
  color: var(--color) !important;
  transition: all 0.3s ease-in-out;
  width: 40px !important;
  height: 30px !important;
  border: 1px solid transparent;
  border-radius: 15px;
}
.PhoneInputCountryIcon--square {
  height: 40px;
  border-radius: 15px;
}
.PhoneInputCountryIconImg {
  border-radius: 5px;
}

.form-name label,
.form-line-of-Business label,
.form-phone-number label,
.form-gmail label,
.form-selected label,
.form-prperty-type label,
.form-required-area label,
.form-Unit label,
.form-brand label {
  font-size: 20px;
  font-weight: 500;
  color: var(--color);
  transition: all 0.3s ease-in-out;
  letter-spacing: 1px;
  font-family: "Montserrat", sans-serif;
  transition: all 0.3s ease-in-out;
}
.form-notes textarea {
  height: 80px !important;
  background-color: var(--background-color);
  color: var(--color2);
}
.form-notes textarea:focus {
  height: 80px !important;
  background-color: var(--background-color);
  color: var(--color2);
}

.form-notes label {
  color: var(--color2);
}
.form-line-of-Business select,
.form-selected select {
  color: var(--color2);
}
.form-input {
  background-color: var(--background-color);
  color: var(--color2);
  width: 100%;
  border-radius: 15px;
  outline: none !important;
  border: 1px solid #a020f0;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.form-brand input {
  height: 50px;
}
.form-input:focus {
  background-color: var(--background-color);
  color: var(--color2);
  border: 1px solid #a020f0;
  outline: none !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.form-line-of-Business,
.form-Unit {
  position: relative;
}
.form-line-of-Business .input-group span,
.form-Unit .input-group span {
  background-color: var(--background-color);
  color: var(--color2);
  border: 1px solid #a020f0;
  outline: none !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  margin: 0 !important;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  font-size: 18px;
}

.form-line-of-Business .drop-down,
.form-Unit .drop-down {
  width: 100%;
  position: absolute;
  top: 70px;
  z-index: 999;
  height: auto;
  max-height: 280px;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  background-color: var(--background-color);
}
.form-line-of-Business .drop-down::-webkit-scrollbar-track,
.form-Unit .drop-down::-webkit-scrollbar-track {
  background-color: var(--background-color);
}
.form-line-of-Business .drop-down table,
.form-Unit .drop-down table {
  border-collapse: collapse;
  width: 100%;
}
.form-line-of-Business .drop-down table th,
.form-Unit .drop-down table th {
  position: sticky;
  top: -5px;
}
.form-line-of-Business .drop-down table tr,
.form-Unit .drop-down table tr {
  scroll-snap-align: start;
}
.form-line-of-Business .drop-down table tr th,
.form-line-of-Business .drop-down table tr td,
.form-Unit .drop-down table tr th,
.form-Unit .drop-down table tr td {
  outline: none !important;
  border: none !important;
  background-color: var(--background-color);
  color: var(--color2);
  padding-left: 20px;
}
.form-line-of-Business .drop-down table tr:first-child th:first-child,
.form-Unit .drop-down table tr:first-child th:first-child {
  border-top-left-radius: 15px;
}
.form-line-of-Business .drop-down table tr:first-child th:last-child,
.form-Unit .drop-down table tr:first-child th:last-child {
  border-top-right-radius: 15px;
}
.form-line-of-Business .drop-down table tr:last-child td:first-child,
.form-Unit .drop-down table tr:last-child td:first-child {
  border-bottom-left-radius: 15px;
}
.form-line-of-Business .drop-down table tr:last-child td:last-child,
.form-Unit .drop-down table tr:last-child td:last-child {
  border-bottom-right-radius: 15px;
}
.line-of-business-arrow,
.Unit-arrow {
  transform: rotateZ(90deg);
}
.form-upload-button,
.form-submit-button {
  height: 40px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.form-button-group {
  display: flex;
  justify-content: space-evenly;
  width: 90%;
  margin: 20px 10px 0 10px;
}
/*------------------------ HOME-FORM ----------------*/
@media screen and (max-width: 1200px) {
  .form-brand,
  .form-notes {
    width: 95%;
  }
  .home-form .form-heading h1 {
    font-size: 2.6rem;
  }
  .form-vector {
    width: 90px;
  }
}
@media screen and (max-width: 900px) {
  .form-name,
  .form-line-of-Business,
  .form-gmail,
  .form-selected,
  .form-prperty-type,
  .form-Unit,
  .form-required-area {
    margin: 0px;
  }
  .form-phone-number {
    margin: 0px;
  }
  .form-brand,
  .form-notes {
    width: 95%;
  }
  .home-rocket {
    width: 100px;
  }
  .home-card-body-h1 {
    font-size: 5rem;
  }
  .form-heading {
    gap: 8px;
  }
  .home-form .form-heading h1 {
    font-size: 2.4rem;
  }
  .form-vector {
    width: 80px;
  }
}
@media screen and (max-width: 770px) {
  .form-name,
  .form-line-of-Business,
  .form-gmail,
  .form-selected,
  .form-prperty-type,
  .form-Unit,
  .form-required-area {
    width: 40%;
  }
  .form-phone-number {
    width: 40%;
  }
  .form-brand,
  .form-notes {
    width: 90%;
  }
  .home-form .form-heading h1 {
    font-size: 2.2rem;
  }
  .form-vector {
    width: 70px;
  }
  .form-name label,
  .form-line-of-Business label,
  .form-phone-number label,
  .form-gmail label,
  .form-selected label,
  .form-prperty-type label,
  .form-Unit label,
  .form-brand label,
  .form-required-area label {
    font-size: 18px;
    letter-spacing: 0.8px;
  }
  .home-form .container form {
    gap: 15px 50px;
  }
  .home::after {
    width: 100%;
    height: 150%;
    top: -40%;
  }
  .home-bg {
    height: 120vh;
  }
}
@media screen and (max-width: 760px) {
  .home-bg {
    height: 140%;
    top: -40%;
  }
}
@media screen and (max-width: 740px) {
  .form-heading {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 50px;
  }
  .form-vector {
    width: 80px;
  }
}
@media screen and (max-width: 576px) {
  .form-name,
  .form-line-of-Business,
  .form-gmail,
  .form-selected,
  .form-prperty-type,
  .form-Unit,
  .form-required-area {
    width: 100%;
  }
  .form-phone-number {
    width: 100%;
  }
  .form-brand,
  .form-notes {
    width: 100%;
  }
  .form-brand {
    margin: 0px;
  }
  .form-notes {
    margin: 10px 0px;
  }
  .form-heading {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 50px;
  }
  .home-form .form-heading h1 {
    font-size: 1.5rem;
  }
  .form-vector {
    width: 60px;
  }
  .form-name label,
  .form-line-of-Business label,
  .form-phone-number label,
  .form-gmail label,
  .form-selected label,
  .form-prperty-type label,
  .form-required-area label,
  .form-Unit label,
  .form-brand label {
    font-size: 18px;
    letter-spacing: 0.8px;
  }
  .home-form .container form {
    gap: 15px 50px;
  }
  .home::after {
    width: 100%;
    height: 150%;
    top: -20%;
  }
  .home-bg {
    height: 100vh;
    height: 120%;
    top: -20%;
  }
  .home-rocket {
    width: 60px;
  }
  .home .home-body {
    gap: 10px;
  }
  .home-card-body-h1 {
    font-size: 3rem;
  }
  .form-button-group {
    justify-content: space-between;
    width: 100%;
  }
}
