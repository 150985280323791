@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

.Maintenance {
  width: 100vw;
  height: 85vh;
  min-height: 70vh;
}
.Maintenance-heading {
  width: 100%;
  height: 100%;
  display: flex;
  /* justify-content: center; */
  padding-left: 10%;
  gap: 5px;
  align-items: center;
  position: relative;
  isolation: isolate;
}
.Maintenance-heading-animation {
  width: 300px;
  height: 50px;
  margin-bottom: 10px;
  transform: rotateZ(-90deg);
  margin-right: 5%;
}
/* .illustration {
  position: absolute;
  height: 200px;
  z-index: 1;
}
.Maintenance-illustration1 {
  bottom: 10%;
  right: -3%;
  transform: rotateZ(-40deg);
}
.Maintenance-illustration2 {
  top: 10%;
  left: -4%;
  transform: rotateZ(20deg);
} */
.MaintenanceHeaderBg {
  position: absolute;
  width: 100%;
  height: 50%;
  top: 0;
  bottom: 0;
  object-fit: cover;
  z-index: -1;
  background-position: center;
  background-size: cover;
  animation: fitImgAnimation 5s ease-in-out infinite alternate-reverse;
}
@keyframes fitImgAnimation {
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}
.Maintenance-heading::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 200%;
  background-color: black;
  top: -100%;
  left: 0;
  z-index: -1;
  opacity: 0.5;
}
.Maintenance-heading h1 {
  color: #fff;
  font-size: 5rem;
  text-align: center;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  text-shadow: 5px 5px 2px black;
  transform-origin: left;
  cursor: pointer;
}
.Maintenance-Form-Section {
  width: 100vw;
  height: auto;
  background-image: var(--Fitform-background);
  transition: all 0.3s ease-in-out;
  background-size: cover;
  background-position: center;
  padding: 100px 0;
  position: relative;
  overflow: hidden;
}
.form-vector1,
.form-vector2,
.form-vector3,
.form-vector4,
.form-vector6 {
  position: absolute;
  filter: blur(1px);
  filter: drop-shadow(#8c8c8c 0.1rem 0.4rem 0.5rem);
}

.form-vector1 {
  top: 10px;
  right: -30px;
  width: 200px;
  height: 200px;
  transform-origin: right;
}
.form-vector2 {
  bottom: -20px;
  right: 100px;
  width: 120px;
  height: 120px;
}
.form-vector3 {
  bottom: 200px;
  left: 35px;
  width: 100px;
  height: 100px;
  animation: vectorAni 3s ease-in-out infinite alternate-reverse;
}
.form-vector4 {
  top: 200px;
  left: -45px;
  width: 150px;
  height: 150px;
  transform-origin: left;
}
.form-vector6 {
  top: 10px;
  right: 45%;
  width: 100px;
  height: 100px;
  animation: vectorAni 3s ease-in-out infinite alternate-reverse;
}
@keyframes vectorAni {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.8);
  }
}
.Maintenance-Form-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}
/* .form-rotate-spinner {
  position: absolute;
  background: linear-gradient(135deg, red, #01ff44);
  width: 150%;
  height: 100%;
  top: 0px;
  left: 0;
  animation: formSpinnerAni 4s ease-in-out infinite;
  animation-play-state: paused;
}
.Fit-Form-Container:hover .form-rotate-spinner {
  animation-play-state: running;
}
@keyframes formSpinnerAni {
  from {
    filter: hue-rotate(0deg);
    transform: rotateZ(0deg);
  }
  to {
    filter: hue-rotate(360deg);
    transfrm: rotateZ(360deg);
  }
} */
.Maintenance-Form-Container form {
  width: 100%;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 10px;
  border-radius: 10px;
  display: flex;
  gap: 30px 30px;
  flex-wrap: wrap;
  box-shadow: #a020f0 0px 1px 20px;
  position: relative;
  isolation: isolate;
  /* overflow: hidden; */
  transform-origin: top;
}
.Maintenance-Form-Container form::before {
  content: "";
  width: 0;
  height: 100%;
  background-color: #a020f0;
  position: absolute;
  opacity: 0.06;
  z-index: -1;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  /* border-radius: 50%; */
  transition: all 0.4s ease-in-out;
}
.Maintenance-Form-Container form:hover::before {
  width: 100%;
}
.Maintenance-RequestType,
.Maintenance-Description,
.Maintenance-FunctionalLocation,
.Maintenance-Asset,
.Maintenance-ServiceLevel,
.Maintenance-FaultSymptom,
.Maintenance-FaultArea,
.Maintenance-FaultType,
.Maintenance-Downtime,
.Maintenance-ActualStart {
  width: 45%;
  position: relative;
}
.Maintenance-Notes {
  width: 92%;
  position: relative;
}
.Maintenance-RequestType input,
.Maintenance-Description input,
.Maintenance-FunctionalLocation input,
.Maintenance-Asset input,
.Maintenance-ServiceLevel input,
.Maintenance-FaultSymptom input,
.Maintenance-FaultArea input,
.Maintenance-FaultType input,
.Maintenance-Downtime input,
.Maintenance-ActualStart input {
  background-color: transparent;
  color: var(--color2);
  border: 0.5px solid var(--maintenance-form-border-color);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  transition: all 0.3s ease-in-out;
}
.Maintenance-RequestType input:focus,
.Maintenance-Description input:focus,
.Maintenance-FunctionalLocation input:focus,
.Maintenance-Asset input:focus,
.Maintenance-ServiceLevel input:focus,
.Maintenance-FaultSymptom input:focus,
.Maintenance-FaultArea input:focus,
.Maintenance-FaultType input:focus,
.Maintenance-Downtime input:focus,
.Maintenance-ActualStart input:focus,
.Maintenance-Notes input:focus {
  background-color: transparent;
  border: 0.5px solid var(--maintenance-form-border-color);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  color: var(--color2);
}
.Maintenance-FaultSymptom input:disabled,
.Maintenance-FaultArea input:disabled,
.Maintenance-FaultType input:disabled {
  background-color: transparent !important;
}
.Maintenance-Notes input {
  background-color: transparent;
  height: 100px !important;
  color: var(--color2);
  border: 0.5px solid var(--maintenance-form-border-color);
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.Maintenance-RequestType label,
.Maintenance-Description label,
.Maintenance-FunctionalLocation label,
.Maintenance-Asset label,
.Maintenance-ServiceLevel label,
.Maintenance-FaultSymptom label,
.Maintenance-FaultArea label,
.Maintenance-FaultType label,
.Maintenance-Downtime label,
.Maintenance-ActualStart label,
.Maintenance-Notes label {
  color: var(--color2);
  letter-spacing: 1.5px;
  word-spacing: 5px;
  font-family: "Inter", sans-serif;
}
.formMaintenance-button-group {
  display: flex;
  justify-content: center;
  width: 65%;
}
.Maintenance-FunctionalLocation .drop-down,
.Maintenance-Asset .drop-down {
  position: absolute;
  top: 57px;
  left: 0px;
  width: 100%;
  z-index: 999;
  height: auto;
  max-height: 280px;
  overflow-y: scroll;
  overflow-x: scroll;
  scroll-snap-type: mandatory;
  background-color: var(--background-color);
}
.Maintenance-RequestType .drop-down,
.Maintenance-ServiceLevel .drop-down,
.Maintenance-FaultSymptom .drop-down,
.Maintenance-FaultArea .drop-down,
.Maintenance-FaultType .drop-down {
  position: absolute;
  top: 57x;
  left: 0px;
  width: 100%;
  z-index: 999;
  height: auto;
  max-height: 280px;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  background-color: var(--background-color);
}

.Maintenance-FunctionalLocation .drop-down::-webkit-scrollbar-corner,
.Maintenance-Asset .drop-down::-webkit-scrollbar-corner {
  background: #000000;
}
.Maintenance-RequestType .drop-down::-webkit-scrollbar-track,
.Maintenance-FunctionalLocation .drop-down::-webkit-scrollbar-track,
.Maintenance-Asset .drop-down::-webkit-scrollbar-track,
.Maintenance-ServiceLevel .drop-down::-webkit-scrollbar-track,
.Maintenance-FaultSymptom .drop-down::-webkit-scrollbar-track,
.Maintenance-FaultArea .drop-down::-webkit-scrollbar-track,
.Maintenance-FaultType .drop-down::-webkit-scrollbar-track {
  background-color: var(--background-color);
}
.Maintenance-RequestType .drop-down table,
.Maintenance-FunctionalLocation.drop-down table,
.Maintenance-Asset .drop-down table,
.Maintenance-ServiceLevel .drop-down table,
.Maintenance-FaultSymptom .drop-down table,
.Maintenance-FaultArea .drop-down table,
.Maintenance-FaultType .drop-down table {
  border-collapse: collapse;
  width: 100%;
}
.Maintenance-RequestType .drop-down table th,
.Maintenance-FunctionalLocation .drop-down table th,
.Maintenance-Asset .drop-down table th,
.Maintenance-ServiceLevel .drop-down table th,
.Maintenance-FaultSymptom .drop-down table th,
.Maintenance-FaultArea .drop-down table th,
.Maintenance-FaultType .drop-down table th {
  position: sticky;
  top: -5px;
}
.Maintenance-RequestType .drop-down table tr th,
.Maintenance-RequestType .drop-down table tr td,
.Maintenance-FunctionalLocation .drop-down table tr th,
.Maintenance-FunctionalLocation .drop-down table tr td,
.Maintenance-Asset .drop-down table tr th,
.Maintenance-Asset .drop-down table tr td,
.Maintenance-ServiceLevel .drop-down table tr th,
.Maintenance-ServiceLevel .drop-down table tr td,
.Maintenance-FaultSymptom .drop-down table tr th,
.Maintenance-FaultSymptom .drop-down table tr td,
.Maintenance-FaultArea .drop-down table tr th,
.Maintenance-FaultArea .drop-down table tr td,
.Maintenance-FaultType .drop-down table tr th,
.Maintenance-FaultType .drop-down table tr td {
  outline: none !important;
  border: none !important;
  background-color: var(--background-color);
  color: var(--color2);
  padding-left: 20px;
}
.table tbody tr td:hover {
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.2),
    rgba(255, 255, 255, 0)
  );
}
@media screen and (max-width: 1200px) {
  .Maintenance-heading {
    padding-right: 12%;
    padding-left: 0%;
  }
  .Maintenance-heading-animation {
    margin-right: 7%;
  }
  .MaintenanceHeaderBg {
    height: 60%;
  }
  .Maintenance-Notes {
    width: 92.5%;
  }
  .form-vector3 {
    bottom: 150px;
    left: -10px;
  }
  .Maintenance-RequestType .drop-down table tr th,
  .Maintenance-RequestType .drop-down table tr td,
  .Maintenance-FunctionalLocation .drop-down table tr th,
  .Maintenance-FunctionalLocation .drop-down table tr td,
  .Maintenance-Asset .drop-down table tr th,
  .Maintenance-Asset .drop-down table tr td,
  .Maintenance-ServiceLevel .drop-down table tr th,
  .Maintenance-ServiceLevel .drop-down table tr td,
  .Maintenance-FaultSymptom .drop-down table tr th,
  .Maintenance-FaultSymptom .drop-down table tr td,
  .Maintenance-FaultArea .drop-down table tr th,
  .Maintenance-FaultArea .drop-down table tr td,
  .Maintenance-FaultType .drop-down table tr th,
  .Maintenance-FaultType .drop-down table tr td {
    font-size: 15px;
  }
}
@media screen and (max-width: 900px) {
  .Maintenance-heading {
    padding-left: 0%;
  }
  .Maintenance-heading-animation {
    width: 200px;
  }
  .Maintenance-heading h1 {
    font-size: 3.5rem;
  }
  .Maintenance-Notes {
    width: 93%;
  }
  .form-vector3 {
    bottom: 100px;
    left: -15px;
  }
  .Maintenance-RequestType .drop-down table tr th,
  .Maintenance-RequestType .drop-down table tr td,
  .Maintenance-FunctionalLocation .drop-down table tr th,
  .Maintenance-FunctionalLocation .drop-down table tr td,
  .Maintenance-Asset .drop-down table tr th,
  .Maintenance-Asset .drop-down table tr td,
  .Maintenance-ServiceLevel .drop-down table tr th,
  .Maintenance-ServiceLevel .drop-down table tr td,
  .Maintenance-FaultSymptom .drop-down table tr th,
  .Maintenance-FaultSymptom .drop-down table tr td,
  .Maintenance-FaultArea .drop-down table tr th,
  .Maintenance-FaultArea .drop-down table tr td,
  .Maintenance-FaultType .drop-down table tr th,
  .Maintenance-FaultType .drop-down table tr td {
    font-size: 12px;
  }
}
@media screen and (max-width: 800px) {
}
@media screen and (max-width: 770px) {
  .MaintenanceHeaderBg {
    height: 55%;
  }
  .Maintenance-Notes {
    width: 94.5%;
  }
  .Maintenance-RequestType label,
  .Maintenance-Description label,
  .Maintenance-FunctionalLocation label,
  .Maintenance-Asset label,
  .Maintenance-ServiceLevel label,
  .Maintenance-FaultSymptom label,
  .Maintenance-FaultArea label,
  .Maintenance-FaultType label,
  .Maintenance-Downtime label,
  .Maintenance-ActualStart label,
  .Maintenance-Notes label {
    font-size: 18x;
  }
}
@media screen and (max-width: 750px) {
  .Maintenance-RequestType label,
  .Maintenance-Description label,
  .Maintenance-FunctionalLocation label,
  .Maintenance-Asset label,
  .Maintenance-ServiceLevel label,
  .Maintenance-FaultSymptom label,
  .Maintenance-FaultArea label,
  .Maintenance-FaultType label,
  .Maintenance-Downtime label,
  .Maintenance-ActualStart label,
  .Maintenance-Notes label {
    font-size: 12px !important;
    word-spacing: 3px;
  }
  .form-vector3 {
    bottom: 100px;
    left: 20px;
  }
}
@media screen and (max-width: 576px) {
  .Maintenance-heading {
    padding-left: 11%;
    justify-content: center;
  }
  .Maintenance-heading-animation {
    width: 200px;
    margin-bottom: 0px;
    margin-left: -120px;
    position: absolute;
    transform: rotateZ(-120deg);
  }
  .Maintenance-heading h1 {
    font-size: 2.5em;
    margin-top: 100px;
  }
  .MaintenanceHeaderBg {
    height: 55%;
  }
  .Maintenance-RequestType,
  .Maintenance-Description,
  .Maintenance-FunctionalLocation,
  .Maintenance-Asset,
  .Maintenance-ServiceLevel,
  .Maintenance-FaultSymptom,
  .Maintenance-FaultArea,
  .Maintenance-FaultType,
  .Maintenance-Downtime,
  .Maintenance-ActualStart {
    width: 100%;
  }
  .Maintenance-Notes {
    width: 100%;
  }
  .form-vector1 {
    width: 150px;
    height: 150px;
  }
  .form-vector3 {
    bottom: 85px;
    left: 5px;
  }
}
@media screen and (max-width: 390px) {
  .MaintenanceHeaderBg {
    height: 40%;
  }
  .Maintenance-Notes input {
    height: 50px !important;
  }
}
