.scroll-top-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background-color: #a020f0;
  border-radius: 50%;
  position: fixed;
  bottom: 50px;
  right: 50px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 10px 38px, rgba(0, 0, 0, 0.22) 0px 0px 12px;
  transition: all 0.3s ease-in-out;
}
.scroll-top-circle:hover {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 0px, rgba(0, 0, 0, 0.22) 0px 0px 0px;
  transition: all 0.3s ease-in-out;
}
.scroll-top-circle:hover .scroll-top-icon {
  animation-play-state: paused;
}
.scroll-top-icon {
  color: #fff;
  font-size: 20px;
  animation: scrollIconAnimation 0.5s linear infinite alternate-reverse;
}
@keyframes scrollIconAnimation {
  from {
    transform: translateY(5px);
  }
  to {
    transform: translateY(-5px);
  }
}
@media screen and (max-width: 576px) {
  .scroll-top-circle {
    width: 40px;
    height: 40px;
  }
  .scroll-top-icon {
    font-size: 16px;
  }
}
