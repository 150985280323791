body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  touch-action: pan-y;
  user-select: none;
  overflow-x: hidden;
  background: #f5f5dc;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  /* --nav-background: linear-gradient(135deg, #dd1188, #330000); */
  --nav-background: linear-gradient(135deg, #301934, #301934, #36454f);
  --Fitnav-background: linear-gradient(135deg, #d9afd9 0%, #97d9e1 100%);
  --form-background: url("./Assets/home/rose-petals-light2.svg");
  --Fitform-background: url("./Assets/fit-out/rose-petals-formBg.svg");
  --home-color: #d9e4f5;
  --color: black;
  --color2: #fff;
  --maintenance-form-border-color: #00ffff;
  --background-color: black;
  --bottom-shadow: 0px 20px 10px -13px black;
  --form-input-shadow: rgba(255, 255, 255, 1) 0px 19px 38px,
    rgba(255, 255, 255, 1) 0px 15px 12px;
  --nav-bg: linear-gradient(45deg, #301934, #301934, #36454f);
  --Fitnav-bg: linear-gradient(135deg, #d9afd9 0%, #97d9e1 100%);
  --Fitform-label-bg: #8c8c8c;
}
.dark-mode {
  --nav-background: linear-gradient(135deg, #fff, #f5f5dc);
  --Fitnav-background: linear-gradient(135deg, #301934, #301934, #36454f);
  --form-background: url("./Assets/home/rose-petals-dark2.svg");
  --Fitform-background: url("./Assets/home/rose-petals-light2.svg");
  --home-color: #d9e4f5;
  --color: #fff;
  --maintenance-form-border-color: #000000;
  --background-color: #fff;
  --color2: black;
  --bottom-shadow: 0px 18px 10px -13px black;
  --form-input-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  --nav-bg: linear-gradient(135deg, #fff, #f5f5dc);
  --Fitnav-bg: linear-gradient(45deg, #301934, #301934, #36454f);
  --Fitform-label-bg: #f5f5dc;
}
::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background-color: black;
}
::-webkit-scrollbar-thumb {
  background-color: #a020f0;
  border-radius: 10px;
}
