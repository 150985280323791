header {
  height: 100px;
  color: #fff;
  display: grid;
  align-content: center;
  z-index: 999;
}
.header-unscroll {
  color: #fff;
  position: relative;
}
.header-scroll {
  color: var(--color2);
  background: var(--nav-background);
  box-shadow: var(--bottom-shadow);
  animation: header-scroll 0.4s ease-in-out;
  transform-origin: top;
}
@keyframes header-scroll {
  from {
    transform: translateY(-100px);
    transform: scaleY(0);
    opacity: 0.5;
  }
  to {
    transform: translateY(0);
    transform: scaleY(1);
    opacity: 1;
  }
}
.header-col1 {
  display: flex;
  justify-content: start;
  align-items: start;
  padding-left: 10px;
  margin-bottom: 40px;
}
.header-col2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-col3 {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.header-col1-subdiv img {
  width: 400px;
  height: 150px;
  filter: drop-shadow(1px 0px 10px black);
}
.hamburger {
  display: none;
}
.mode-class {
  display: flex;
  justify-content: center;
}
.mode-label {
  width: 60px;
  height: 30px;
  background-color: #c1c1c1;
  position: relative;
  box-shadow: #a020f0 0px 1px 15px;
}
.mode-label::before {
  content: "";
  width: 27px;
  height: 27px;
  background-color: rgb(31, 29, 29);
  border-radius: 50%;
  position: absolute;
  margin: 1.5px;
  transition: all 0.3s ease-in-out;
  box-shadow: #a020f0 0px 0px 6px 2px inset;
}
input[type="checkbox"] {
  display: none;
}
input[type="checkbox"]:checked + .mode-label {
}
input[type="checkbox"]:checked + .mode-label::before {
  transform: translateX(30px);
  transition: all 0.3s ease-in-out;
}
.header-sign-in-button {
  position: relative;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
}
.header-sign-in-button::after {
  content: "";
  position: absolute;
  width: 110%;
  height: 120%;
  background-color: #8c8c8c;
  border-radius: 100px;
  z-index: -1;
  bottom: -3.3px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 0px 10px;
  transition: all 0.3s ease-in-out;
}
.header-sign-in-button:hover::after {
  width: 0%;
  height: 0%;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 1200px) {
  .header-col1-subdiv img {
    margin-left: -30px;
  }
}
@media screen and (max-width: 900px) {
  .header-col1-subdiv img {
    margin-left: -40px;
  }
}
@media screen and (max-width: 576px) {
  .header-unscroll {
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    );
  }
  .header-col1-subdiv img {
    width: 300px;
    height: 120px;
  }
  .header-col1 {
    display: flex;
    justify-content: start;
    align-items: start;
    margin-bottom: 20px;
  }
  .header-col2 {
    display: flex;
    justify-content: center;
    /* align-items: center; */
    /* position: relative; */
  }
  .header-col3 {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
  }
  .hamburger {
    display: block;
  }
  .nav-hide {
    display: none;
  }
  .nav-show {
    display: block;
  }
}
