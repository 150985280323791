@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: "sans-serif";
  box-sizing: border-box;
}

#Login {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../Assets/sign-in/rose-petals-signBg.svg");
  background-size: cover;
  background-position: center;
  min-height: 100vh;
}

.login-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container .login-spinner {
  position: absolute;
  margin-left: -225px;
  width: 32px;
  height: 6px;
  background: #000000;
  border-radius: 8px;
  transform-origin: 128px;
  transform: scale(2.2) rotate(calc(var(--i) * (360deg / 50)));
  animation: animateBlink 3s linear infinite;
  animation-delay: calc(var(--i) * (3s / 50));
}

@keyframes animateBlink {
  0% {
    /* background: #00ffff; */
    background: #00ffff;
    opacity: 0.3;
  }

  25% {
    background: #ffffff;
    opacity: 0.2;
  }
}
.login-box {
  width: 400px;
}

.login-box .login-box-form {
  width: 100%;
  padding: 0 50px;
}

.signIn-heading {
  font-size: 2em;
  color: #fff;
  text-align: center;
  font-weight: 700;
}
.username,
.password {
  position: relative;
  margin: 25px 0;
}

.username input,
.password input {
  width: 100%;
  height: 50px;
  background: transparent;
  border: 2px solid #301934;
  outline: none;
  border-radius: 40px;
  font-size: 1em;
  color: #fff;
  padding: 0 20px;
  transition: 0.5s;
}

.username input:focus,
.username input:valid,
.password input:focus,
.password input:valid {
  border-color: #a020f0;
}

.username label,
.password label {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
  font-size: 1em;
  color: #fff;
  pointer-events: none;
  transition: 0.5s ease;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
}

.username input:focus ~ label,
.username input:valid ~ label,
.password input:focus ~ label,
.password input:valid ~ label {
  top: 1px;
  font-size: 0.8em;
  background-color: #8c8c8c;
  border-radius: 100px;
  padding: 0 6px;
  color: #fff;
  transition: all 0.2s ease-in-out;
}
.pass-span {
  color: #fff;
  font-size: 25px;
  position: absolute;
  right: 20px;
  top: 5px;
}

.btn-SignIn {
  width: 100%;
  height: 40px;
  border-radius: 45px;
  background: #a020f0;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 1.2em;
  color: #fff;
  font-weight: 700;
}
.btn-ForgotPass-div {
  display: flex;
  justify-content: center;
  padding-top: 12px;
}
.btn-ForgotPass {
  font-weight: 700;
  font-size: 1.2em;
  color: #fff;
  border: none;
}
.btn-ForgotPass:hover {
  color: #301934;
}
@media screen and (max-width: 750px) {
  #Login {
    min-height: 200vh;
  }
}
@media screen and (max-width: 576px) {
  #Login {
    min-height: 100vh;
  }
  .login-container .login-spinner {
    margin-left: -160px;
    width: 10px;
    height: 4px;
    transform-origin: 85px;
  }
  .login-box .login-box-form {
    padding: 0 50px;
  }
  .signIn-heading {
    font-size: 1.8em;
  }
  .username,
  .password {
    margin: 15px 0;
  }
  .btn-ForgotPass-div {
    padding-top: 8px;
  }
}
@media screen and (max-width: 360px) {
  .login-container .login-spinner {
    margin-left: -148px;
    width: 10px;
    height: 4px;
    transform-origin: 80px;
  }
}
