@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&family=Rubik+Bubbles&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Pacifico&family=Rubik+Bubbles&display=swap");
/*------------------------ Event-MAIN ----------------*/
.Event {
  display: grid;
  place-content: center;
  height: 85vh;
  box-shadow: var(--bottom-shadow);
  transition: all 0.3s ease-in-out;
  position: relative;
}
.Event::after {
  content: "";
  background-color: black;
  width: 100%;
  height: 120%;
  position: absolute;
  top: -20%;
  left: 0;
  z-index: -1;
  opacity: 0.4;
}
.Event::before {
  content: "";
  width: 100%;
  height: 3px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  opacity: 0;
  background-color: #a020f0;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 1s ease-in-out;
}
.Event-bg {
  position: absolute;
  width: 100%;
  height: 120%;
  top: -20%;
  bottom: 0;
  object-fit: cover;
  z-index: -1;
  background-position: center;
  background-size: cover;
  animation: imgAnimation 5s ease-in-out infinite alternate-reverse;
}
@keyframes imgAnimation {
  from {
    transform: scale(1.2);
  }
  to {
    transform: scale(1);
  }
}

@keyframes rocketAni {
  from {
    transform: translateY(10px);
  }
  to {
    transform: translateY(-10px);
  }
}
.Event .Event-body {
  background-color: transparent;
  display: flex;
  width: 100vw;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.Event-card-body-h1 {
  color: #fff;
  font-size: 5rem;
  text-align: center;
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  font-weight: 500;
  text-shadow: 5px 5px 2px black;
  cursor: pointer;
}
/*------------------------ Event-MAIN ----------------*/

/*------------------------ Event-FORM ----------------*/

.Permit-button {
  display: flex;
  justify-content: center;
  margin-top: -50px;
  margin-bottom: 30px;
  position: relative;
}
.Permit-button ul {
  position: absolute;
  top: 50%;
}

.Event-form {
  width: 100vw;
  background-image: var(--Fitform-background);
  transition: all 0.3s ease-in-out;
  background-size: cover;
  background-position: center;
  padding: 100px 0;
  position: relative;
  overflow: hidden;
}
.Evente-form .container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 100px;
}
.Event-HeadingAnimation {
  width: 100px;
}
.Eventform-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 100px;
}
.Event-form .Eventform-heading h1 {
  color: var(--color);
  transition: all 0.3s ease-in-out;
  text-align: center;
  font-size: 3rem;
  cursor: pointer;
}
.Eventform-vector {
  width: 100px;
  animation: formVectorAnimation 1s linear infinite alternate-reverse;
}
@keyframes formVectorAnimation {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}
.Event-CrackersAnimation2 {
  position: absolute;
  bottom: 0;
  right: 0;
  filter: blur(4px);
  width: 500px;
}
.Event-CrackersAnimation {
  position: absolute;
  top: 0;
  left: 35%;
  filter: blur(1px);
  width: 400px;
}
.Event-WindmillAnimation {
  width: 250px;
  position: absolute;
  bottom: -3%;
  left: -2%;
  filter: blur(2px);
}

.balloon {
  position: absolute;
  width: 400px;
  transform-origin: bottom;
  filter: blur(5px);
}

.balloon img {
  object-fit: cover;
  width: 400px;
  transform-origin: bottom;
  filter: drop-shadow(#000000 0.1rem 0.4rem 0.5rem);
}
.balloon1 {
  bottom: -30%;
  left: 0;
  animation: balloonAni1 12s ease-in-out infinite;
}
@keyframes balloonAni1 {
  0% {
    transform: translateY(200%);
    transform: rotateZ(0deg);
  }
  10% {
    transform: rotateZ(10deg);
  }
  40% {
    transform: rotateZ(20deg);
  }

  100% {
    transform: translateY(-400%);
  }
}
.balloon2 {
  bottom: -30%;
  left: 20%;
  animation: balloonAni2 12s ease-in-out 1s infinite;
}
@keyframes balloonAni2 {
  0% {
    transform: translateY(200%);
    transform: rotateZ(0deg);
  }
  10% {
    transform: rotateZ(-10deg);
  }
  40% {
    transform: rotateZ(-20deg);
  }

  100% {
    transform: translateY(-400%);
  }
}
.balloon3 {
  bottom: -30%;
  left: 50%;
  animation: balloonAni3 12s ease-in-out 2s infinite;
}
@keyframes balloonAni3 {
  0% {
    transform: translateY(200%);
    transform: rotateZ(0deg);
  }
  10% {
    transform: rotateZ(10deg);
  }
  40% {
    transform: rotateZ(20deg);
  }

  100% {
    transform: translateY(-400%);
  }
}
.balloon4 {
  bottom: -30%;
  left: 80%;
  animation: balloonAni4 12s ease-in-out 0.5s infinite;
}
@keyframes balloonAni4 {
  0% {
    transform: translateY(200%);
    transform: rotateZ(0deg);
  }
  10% {
    transform: rotateZ(-10deg);
  }
  40% {
    transform: rotateZ(-20deg);
  }

  100% {
    transform: translateY(-400%);
  }
}

.star {
  position: absolute;
  width: 200px;
}
.star img {
  object-fit: cover;
  filter: drop-shadow(#000000 0.1rem 0.4rem 0.5rem);
  animation: starAni 5s ease-in-out infinite alternate-reverse;
}
@keyframes starAni {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}
.star1 {
  top: 15%;
  left: 20%;
  filter: blur(1px);
  transform: rotateZ(45deg);
}
.star1 img {
  width: 50px;
}
.star2 {
  top: 30%;
  left: 45%;
  filter: blur(1px);
  transform: rotateZ(45deg);
}
.star2 img {
  width: 80px;
}
.star3 {
  top: 50%;
  left: 1%;
  filter: blur(2px);
  transform: rotateZ(45deg);
}
.star3 img {
  width: 50px;
}
.star5 {
  top: 15;
  right: -5%;
  filter: blur(2px);
  transform: rotateZ(45deg);
}
.star5 img {
  width: 80px;
}
.star4 {
  top: 58%;
  left: 70%;
  filter: blur(1px);
  transform: rotateZ(-45deg);
}
.star4 img {
  width: 70px;
}
.star6 {
  bottom: 12%;
  left: 15%;
  filter: blur(1px);
  transform: rotateZ(-45deg);
}
.star6 img {
  width: 70px;
}
.Event-form .container form {
  width: 100%;
  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0)
  );
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 10px;
  border-radius: 10px;
  display: flex;
  gap: 10px 100px;
  flex-wrap: wrap;
  box-shadow: #a020f0 0px 1px 15px;
  position: relative;
  isolation: isolate;
  overflow: hidden;
  transform-origin: top;
}
.Event-form .container form::before {
  content: "";
  width: 0;
  height: 0;
  background-color: #a020f0;
  position: absolute;
  opacity: 0.1;
  z-index: -1;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 50%;
  transition: all 0.5s ease-in-out;
}
.Event-form .container form:hover::before {
  width: 150%;
  height: 150%;
}
.Eventform-name,
.Eventform-line-of-Business,
.Eventform-gmail,
.Eventform-selected,
.Eventform-prperty-type,
.Eventform-Unit,
.Eventform-required-area,
.Eventform-Permit-date,
.Eventform-brand,
.Eventform-phone-number,
.Eventform-Special-request,
.Eventform-Civil-id {
  width: 40%;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.Eventform-notes {
  width: 90%;
  margin: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.Eventform-input-phone-div {
  width: 100%;
}
.Eventform-input-phone {
  width: 100%;
  outline: none !important;
}
.Eventform-input-phone {
  background-color: transparent !important;
}

.Eventform-name label,
.Eventform-line-of-Business label,
.Eventform-phone-number label,
.Eventform-gmail label,
.Eventform-selected label,
.Eventform-prperty-type label,
.Eventform-required-area label,
.Eventform-Unit label,
.Eventform-Special-request label,
.Eventform-Permit-date label,
.Eventform-brand label,
.Eventform-Civil-id label {
  font-size: 20px;
  font-weight: 500;
  color: var(--color2);
  transition: all 0.3s ease-in-out;
  letter-spacing: 1px;
  font-family: "Montserrat", sans-serif;
  transition: all 0.3s ease-in-out;
}
.Eventform-notes textarea {
  height: 80px !important;
  background-color: transparent;
  color: var(--color2);
}
.Eventform-notes textarea:focus {
  height: 80px !important;
  background-color: transparent;
  color: var(--color2);
}

.Eventform-notes label {
  color: var(--color2);
}
.Eventform-line-of-Business select,
.Eventform-selected select {
  color: var(--color2);
  background-color: transparent !important;
}
.Eventform-input {
  background-color: transparent;
  color: var(--color2);
  width: 100%;
  border-radius: 15px;
  outline: none !important;
  border: 1px solid #a020f0;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
/* .Eventform-Special-request input {
  height: 50px;
  background-color: transparent !important;
} */
.Eventform-input:focus {
  background-color: transparent;
  color: var(--color2);
  border: 1px solid #a020f0;
  outline: none !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.Eventform-line-of-Business,
.Eventform-Unit {
  position: relative;
}
.Eventform-line-of-Business .input-group span,
.Eventform-Unit .input-group span {
  background-color: transparent;
  color: var(--color2);
  border: 1px solid #a020f0;
  outline: none !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  margin: 0 !important;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  font-size: 18px;
}
.Eventform-selected select option,
.Eventform-prperty-type select option {
  background-color: var(--color);
}
.Eventform-line-of-Business .drop-down,
.Eventform-Unit .drop-down {
  width: 100%;
  position: absolute;
  top: 70px;
  z-index: 999;
  height: auto;
  max-height: 280px;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-snap-type: y mandatory;
  background-color: var(--background-color);
}
.Eventform-line-of-Business .drop-down::-webkit-scrollbar-track,
.Eventform-Unit .drop-down::-webkit-scrollbar-track {
  background-color: var(--background-color);
}
.Eventform-line-of-Business .drop-down table,
.Eventform-Unit .drop-down table {
  border-collapse: collapse;
  width: 100%;
}
.Eventform-line-of-Business .drop-down table th,
.Eventform-Unit .drop-down table th {
  position: sticky;
  top: -5px;
}
.Eventform-line-of-Business .drop-down table tr,
.Eventform-Unit .drop-down table tr {
  scroll-snap-align: start;
}
.Eventform-line-of-Business .drop-down table tr th,
.Eventform-line-of-Business .drop-down table tr td,
.Eventform-Unit .drop-down table tr th,
.Eventform-Unit .drop-down table tr td {
  outline: none !important;
  border: none !important;
  background-color: var(--background-color);
  color: var(--color2);
  padding-left: 20px;
}
.Eventform-line-of-Business .drop-down table tr:first-child th:first-child,
.Eventform-Unit .drop-down table tr:first-child th:first-child {
  border-top-left-radius: 15px;
}
.Eventform-line-of-Business .drop-down table tr:first-child th:last-child,
.Eventform-Unit .drop-down table tr:first-child th:last-child {
  border-top-right-radius: 15px;
}
.Eventform-line-of-Business .drop-down table tr:last-child td:first-child,
.Eventform-Unit .drop-down table tr:last-child td:first-child {
  border-bottom-left-radius: 15px;
}
.Eventform-line-of-Business .drop-down table tr:last-child td:last-child,
.Eventform-Unit .drop-down table tr:last-child td:last-child {
  border-bottom-right-radius: 15px;
}
.line-of-business-arrow,
.Unit-arrow {
  transform: rotateZ(90deg);
}
.Eventform-upload-button,
.Eventform-submit-button {
  height: 40px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.Eventform-button-group {
  display: flex;
  justify-content: space-evenly;
  width: 90%;
  margin: 20px 10px 0 10px;
}
/*------------------------ Event-FORM ----------------*/
@media screen and (max-width: 1200px) {
  .Event .Event-body {
    margin-left: -50px;
  }
  .Event-form .Eventform-heading h1 {
    font-size: 2.6rem;
  }
  .Eventform-vector {
    width: 90px;
  }
  .Event-CrackersAnimation {
    left: 25%;
  }
  .balloon1 {
    bottom: -30%;
    left: -5%;
  }

  .balloon2 {
    bottom: -30%;
    left: 15%;
  }

  .balloon3 {
    bottom: -30%;
    left: 45%;
  }

  .balloon4 {
    bottom: -30%;
    left: 75%;
  }
}
@media screen and (max-width: 900px) {
  .Eventform-name,
  .Eventform-line-of-Business,
  .Eventform-gmail,
  .Eventform-selected,
  .Eventform-prperty-type,
  .Eventform-Unit,
  .Eventform-required-area,
  .Eventform-Permit-date,
  .Eventform-brand,
  .Eventform-Special-request,
  .Eventform-Civil-id {
    margin: 0px;
  }
  .Eventform-phone-number {
    margin: 0px;
  }
  .Eventform-notes {
    width: 95%;
  }
  .Event-rocket {
    width: 100px;
  }
  .Event-HeadingAnimation {
    width: 90px;
    margin-top: -5px;
  }
  .Event-card-body-h1 {
    font-size: 4rem;
  }
  .Eventform-heading {
    gap: 8px;
  }
  .Event-form .Eventform-heading h1 {
    font-size: 2.4rem;
  }
  .Eventform-vector {
    width: 80px;
  }
  .balloon1 {
    bottom: -30%;
    left: -8%;
  }

  .balloon2 {
    bottom: -30%;
    left: 10%;
  }

  .balloon3 {
    bottom: -30%;
    left: 40%;
  }

  .balloon4 {
    bottom: -30%;
    left: 70%;
  }
}
@media screen and (max-width: 770px) {
  .Eventform-name,
  .Eventform-line-of-Business,
  .Eventform-gmail,
  .Eventform-selected,
  .Eventform-prperty-type,
  .Eventform-Unit,
  .Eventform-required-area,
  .Eventform-Permit-date,
  .Eventform-brand,
  .Eventform-Special-request,
  .Eventform-Civil-id {
    width: 40%;
  }
  .Eventform-phone-number {
    width: 40%;
  }
  .Eventform-notes {
    width: 90%;
  }
  .Event-form .Eventform-heading h1 {
    font-size: 2.2rem;
  }
  .Eventform-vector {
    width: 70px;
  }
  .Eventform-name label,
  .Eventform-line-of-Business label,
  .Eventform-phone-number label,
  .Eventform-gmail label,
  .Eventform-selected label,
  .Eventform-prperty-type label,
  .Eventform-Unit label,
  .Eventform-brand label,
  .Eventform-required-area label,
  .Eventform-Permit-date label,
  .Eventform-Special-request label,
  .Eventform-Civil-id label {
    font-size: 18px;
    letter-spacing: 0.8px;
  }
  .Event-form .container form {
    gap: 15px 50px;
  }
  .Event::after {
    width: 100%;
    height: 150%;
    top: -40%;
  }
  .Event-bg {
    height: 120vh;
  }
}
@media screen and (max-width: 760px) {
  .Event-bg {
    height: 140%;
    top: -40%;
  }
}
@media screen and (max-width: 740px) {
  .Eventform-heading {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 50px;
  }
  .Eventform-vector {
    width: 80px;
  }
}
@media screen and (max-width: 576px) {
  .Event .Event-body {
    margin-left: -30px;
  }
  .Event-card-body-h1 {
    font-size: 1.5rem !important;
  }
  .Eventform-name,
  .Eventform-line-of-Business,
  .Eventform-gmail,
  .Eventform-selected,
  .Eventform-prperty-type,
  .Eventform-Unit,
  .Eventform-required-area,
  .Eventform-Permit-date,
  .Eventform-brand,
  .Eventform-Special-request,
  .Eventform-Civil-id {
    width: 100%;
  }
  .Eventform-phone-number {
    width: 100%;
  }

  .Eventform-notes {
    width: 100%;
  }
  .Eventform-Special-request {
    margin: 0px;
  }
  .Eventform-notes {
    margin: 10px 0px;
  }
  .Eventform-heading {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 50px;
  }
  .Event-form .Event-heading h1 {
    font-size: 1.5rem;
  }
  .Eventform-vector {
    width: 60px;
  }
  .Eventform-name label,
  .Eventform-line-of-Business label,
  .Eventform-phone-number label,
  .Eventform-gmail label,
  .Eventform-selected label,
  .Eventform-prperty-type label,
  .Eventform-required-area label,
  .Eventform-Unit label,
  .Eventform-brand label,
  .Eventform-Permit-date label,
  .Eventform-Special-request label,
  .Eventform-Civil-id label {
    font-size: 18px;
    letter-spacing: 0.8px;
  }
  .Event-form .container form {
    gap: 15px 50px;
  }
  .Event::after {
    width: 100%;
    height: 150%;
    top: -20%;
  }
  .Event-bg {
    height: 100vh;
    height: 120%;
    top: -20%;
  }
  .Event-rocket {
    width: 60px;
  }
  .Event .Event-body {
    gap: 10px;
  }
  .Event-HeadingAnimation {
    width: 50px;
    margin-top: -8px;
  }
  .Event-card-body-h1 {
    font-size: 1.8rem;
  }
  .Eventform-button-group {
    justify-content: space-between;
    width: 100%;
  }
  .Event-CrackersAnimation {
    left: 0;
  }
  .balloon {
    width: 200px;
  }

  .balloon img {
    width: 200px;
  }
  .balloon1 {
    bottom: -30%;
    left: -13%;
    animation: balloonAni1 20s ease-in-out infinite;
  }
  @keyframes balloonAni1 {
    0% {
      transform: translateY(200%);
      transform: rotateZ(0deg);
    }
    10% {
      transform: rotateZ(10deg);
    }
    40% {
      transform: rotateZ(20deg);
    }

    100% {
      transform: translateY(-1100%);
    }
  }
  .balloon2 {
    bottom: -30%;
    left: 5%;
    animation: balloonAni2 20s ease-in-out 1s infinite;
  }
  @keyframes balloonAni2 {
    0% {
      transform: translateY(200%);
      transform: rotateZ(0deg);
    }
    10% {
      transform: rotateZ(-10deg);
    }
    40% {
      transform: rotateZ(-20deg);
    }

    100% {
      transform: translateY(-1100%);
    }
  }
  .balloon3 {
    bottom: -30%;
    left: 30%;
    animation: balloonAni3 20s ease-in-out 2s infinite;
  }
  @keyframes balloonAni3 {
    0% {
      transform: translateY(200%);
      transform: rotateZ(0deg);
    }
    10% {
      transform: rotateZ(10deg);
    }
    40% {
      transform: rotateZ(20deg);
    }

    100% {
      transform: translateY(-1100%);
    }
  }
  .balloon4 {
    bottom: -30%;
    left: 60%;
    animation: balloonAni4 20s ease-in-out 0.5s infinite;
  }
  @keyframes balloonAni4 {
    0% {
      transform: translateY(200%);
      transform: rotateZ(0deg);
    }
    10% {
      transform: rotateZ(-10deg);
    }
    40% {
      transform: rotateZ(-20deg);
    }

    100% {
      transform: translateY(-1100%);
    }
  }
}
@media screen and (max-width: 360px) {
  .Event-HeadingAnimation {
    width: 50px;
    margin-top: -8px;
  }
  .Event-card-body-h1 {
    font-size: 1.5rem;
  }
}
