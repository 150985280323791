@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
nav {
  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: center;
  margin-top: 4px;
}

.nav-link {
  font-family: "Poppins", sans-serif;
  text-decoration: none;
  letter-spacing: 2px;
  font-weight: 400;
  font-size: 18px;
  text-transform: uppercase;
  border: 0.5px solid transparent;
  padding: 5px 15px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.nav-link:hover {
  border: 0.5px solid #a020f0;
  color: #a020f0;
  transition: all 0.3s ease-in-out;
}
.nav-link-arrow {
  font-size: 25px;
  transition: all 0.3s ease-in-out;
}
.properties,
.eoi,
.fitout,
.project,
.search {
  position: relative;
  isolation: isolate;
}
.dropdownmenu-properties {
  background-color: #a020f0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 50px;
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  position: absolute;
  margin-top: 15px;
  transform-origin: top;
  transform: scaleY(0);
  transition: all 0.3s ease-in-out;
}
.dropdownmenu-eoi {
  background-color: #a020f0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  height: 50px;
  width: 120%;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  position: absolute;
  margin-top: 15px;
  transform-origin: top;
  transform: scaleY(0);
  transition: all 0.3s ease-in-out;
}
.dropdownmenu-fitout {
  background-color: #a020f0;
  display: grid;
  place-content: center;
  border-radius: 10px;
  height: 100px;
  gap: 20px;
  width: 130%;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  position: absolute;
  margin-top: 15px;
  transform-origin: top;
  transform: scaleY(0);
  transition: all 0.3s ease-in-out;
}
.dropdownmenu-project {
  background-color: #a020f0;
  display: grid;
  place-content: center;
  border-radius: 10px;
  height: 100px;
  gap: 20px;
  width: 110%;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-size: 16px;
  position: absolute;
  margin-top: 15px;
  transform-origin: top;
  transform: scaleY(0);
  transition: all 0.3s ease-in-out;
}
.dropdownmenu-search {
  background-color: transparent;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250%;
  position: absolute;
  margin-top: 15px;
  transform-origin: top;
  transform: scaleY(0);
  font-size: 20px;
  transition: all 0.3s ease-in-out;
}
.dropdownmenu-search input {
  width: 200px;
  height: 30px;
  background-color: rgba(160, 32, 240, 0.7);
  color: #fff;
  border: 1px solid #a020f0;
  border-radius: 5px;
  border: none !important;
  outline: none;
  font-size: 15px !important;
  box-shadow: #fff 0px 1px 8px;
}
.dropdownmenu-search input:focus {
  border: none !important;
}
.dropdownmenu-search span {
  width: 40px;
  height: 30px;
  font-weight: bold;
  background-color: rgba(160, 32, 240, 0.7);
  color: #fff;
  align-items: center;
  box-shadow: #fff 0px 1px 8px;
  border: none !important;
}
.dropdownmenu-properties::before,
.dropdownmenu-eoi::before,
.dropdownmenu-fitout::before,
.dropdownmenu-project::before {
  content: "";
  width: 20px;
  height: 20px;
  background-color: #a020f0;
  position: absolute;
  top: -8px;
  left: 30px;
  transform: rotateZ(45deg);
  z-index: -1;
}
/* .dropdownmenu-search::before {
  content: "";
  width: 20px;
  height: 20px;
  background-color: rgba(160, 32, 240, 0.5);
  position: absolute;
  top: -8px;
  left: 50px;
  transform: rotateZ(45deg);
  z-index: -1;
} */
.properties:hover .dropdownmenu-properties {
  transform: scaleY(1);
  transition: all 0.3s ease-in-out;
}
.properties:hover .nav-link-arrow {
  transform: rotateZ(90deg);
  color: #a020f0;
}
.eoi:hover .dropdownmenu-eoi {
  transform: scaleY(1);
  transition: all 0.3s ease-in-out;
}
.eoi:hover .nav-link-arrow {
  transform: rotateZ(90deg);
  color: #a020f0;
}
.fitout:hover .dropdownmenu-fitout {
  transform: scaleY(1);
  transition: all 0.3s ease-in-out;
}
.fitout:hover .nav-link-arrow {
  transform: rotateZ(90deg);
  color: #a020f0;
}
.project:hover .dropdownmenu-project {
  transform: scaleY(1);
  transition: all 0.3s ease-in-out;
}
.project:hover .nav-link-arrow {
  transform: rotateZ(90deg);
  color: #a020f0;
}
.search:hover .dropdownmenu-search {
  transform: scaleY(1);
  transition: all 0.3s ease-in-out;
}
.search:hover .nav-link-arrow {
  transform: rotateZ(90deg);
  color: #a020f0;
}
@media screen and (max-width: 576px) {
  nav {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 100vw;
    height: auto;
    padding: 20px 0 20px 10px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    gap: 10px;
    left: -0.3px;
    top: 95px;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    );
    justify-content: flex-start;
    align-items: flex-start;
    animation: navAnuimation 0.5s ease-in-out;
    transform-origin: top;
    box-shadow: var(--bottom-shadow);
  }
  .nav-scroll {
    background: var(--nav-bg);
  }
  @keyframes navAnuimation {
    from {
      transform: scaleY(0);
    }
    to {
      transform: scaleY(1);
    }
  }
  .nav-link {
    letter-spacing: 1.8px;
    font-size: 16px;
  }
  .nav-link:hover {
    border: 0.5px solid #fff;
    color: #fff;
    transition: all 0.3s ease-in-out;
  }
}
